`
<template>
  <c2-minigame
    v-if="isLogged"
    id="vergo-minigame"
    class="mini-game vergo-minigame"
    :class="{ hide: isHidden || !showIcon, mobile: $device.isMobileOrTablet }"
    :pos="`auto ${shiftTop} ${shiftLeft} auto`"
    :token="store.currentUser?.tp_token"
    :partner="brandCode"
    :logo="LOGO"
    :icon="LOGO"
  />
  <div
    v-show="showIcon && !isLogged"
    ref="minigame"
    class="mini-game"
    :class="{ mobile: $device.isMobileOrTablet, hide: isHidden }"
    :style="{ top: shiftTop + 'px', left: shiftLeft + 'px' }"
  >
    <BaseImg class="image" :src="LOGO" alt="icon mini game" />
  </div>
</template>

<script setup>
import { PACKAGE_ID_BLOCK_GAME, PAGE_SHOW_MINIGAME } from '~/constants/lobby'
import { useAppStore } from '~/store/app'
import { MODAL_TYPES, PATH_IMAGES } from '~/config/constant'
const { onClickAuthor } = useHeader()
const { $device, $pinia, $config } = useNuxtApp()
const store = useAppStore($pinia)
const route = useRoute()

const LOGO = '/assets/images/icon-minigame.webp'

const trueWidth = ref($device.isDesktop ? 90 : 60)
const trueHeight = ref($device.isDesktop ? 90 : 60)
const shiftLeft = ref(0)
const shiftTop = ref(0)
const shiftX = ref(0)
const shiftY = ref(0)
const initShiftLeft = ref(0)
const initShiftTop = ref(0)
const viewportHeight = ref(window.innerHeight)
const viewportWidth = ref(window.innerWidth)
const isDrag = ref(false)
const INPUT_NODE_NAMES = ref(['INPUT', 'TEXTAREA', 'SELECT'])
const minigame = ref(null)
const isHidden = ref(false)
const showIcon = computed(() => {
  return PAGE_SHOW_MINIGAME.includes(route.name) && !PACKAGE_ID_BLOCK_GAME.includes(store.currentUser?.package_id)
})

const handler = () => {
  if (process.client) {
    initPositionMiniGame()
  }
}

watch(route, (val) => {
  if (val.query.popup || val.name) {
    handler()
  }
})

onMounted(() => {
  nextTick(() => {
    addDraggableListeners()
    initPositionMiniGame()
  })
})

const isLogged = computed(() => {
  return store.currentUser
})

const brandCode = computed(() => {
  return useCookie('brand_code').value
})

const login = () => {
  onClickAuthor(MODAL_TYPES.LOGIN, 'login')
}

const setDimensions = ({ width, height }) => {
  if (initShiftLeft.value === shiftLeft.value && initShiftTop.value === shiftTop.value) {
    initPositionMiniGame()
  }
}

const initPositionMiniGame = () => {
  shiftLeft.value = $device.isDesktop ? window.innerWidth - 120 : window.innerWidth - 80 * ($device.isTablet ? 2.25 : 1)
  shiftTop.value = $device.isDesktop
    ? window.innerHeight - 160
    : window.innerHeight - 150 * ($device.isTablet ? 2.1 : 1)
  initShiftLeft.value = shiftLeft.value
  initShiftTop.value = shiftTop.value
}

const isInput = (element) => {
  return element && INPUT_NODE_NAMES.value.includes(element.nodeName)
}
const getTouchEvent = (event) => {
  return event.touches && event.touches.length > 0 ? event.touches[0] : event
}
const getDraggableElement = () => {
  return minigame.value
}

const addDraggableListeners = () => {
  const dragger = getDraggableElement()
  if (dragger) {
    let startX = 0
    let startY = 0
    let initialShiftLeft = 0
    let initialShiftTop = 0
    let clickTimeout = null
    const handleDraggableMousedown = (event) => {
      const target = event.target
      if (isInput(target)) {
        return
      }
      isDrag.value = false
      const { clientX, clientY } = getTouchEvent(event)
      startX = clientX
      startY = clientY
      initialShiftLeft = shiftLeft.value
      initialShiftTop = shiftTop.value
      clickTimeout = setTimeout(() => {
        isDrag.value = true
      }, 150)
      if ($device.isDesktop) {
        document.addEventListener('mousemove', handleDraggableMousemove)
        document.addEventListener('touchmove', handleDraggableMousemove)
        document.addEventListener('mouseup', handleDraggableMouseup)
        document.addEventListener('touchend', handleDraggableMouseup)
      } else {
        dragger.addEventListener('mousemove', handleDraggableMousemove)
        dragger.addEventListener('touchmove', handleDraggableMousemove)
        dragger.addEventListener('mouseup', handleDraggableMouseup)
        dragger.addEventListener('touchend', handleDraggableMouseup)
      }
      event.preventDefault()
    }
    const handleDraggableMousemove = (event) => {
      const { clientX, clientY } = getTouchEvent(event)
      const newShiftLeft = initialShiftLeft + clientX - startX
      const newShiftTop = initialShiftTop + clientY - startY
      const maxLeft = viewportWidth.value - trueWidth.value
      const maxTop = viewportHeight.value - trueHeight.value
      shiftLeft.value = Math.min(Math.max(newShiftLeft, 0), maxLeft)
      shiftTop.value = Math.min(Math.max(newShiftTop, 0), maxTop)
      event.preventDefault()
    }
    const handleDraggableMouseup = (event) => {
      clearTimeout(clickTimeout)
      if (!isDrag.value) {
        login()
      }
      isDrag.value = false
      ensureShiftInWindowBounds()
      if ($device.isDesktop) {
        document.removeEventListener('mousemove', handleDraggableMousemove)
        document.removeEventListener('touchmove', handleDraggableMousemove)
        document.removeEventListener('mouseup', handleDraggableMouseup)
        document.removeEventListener('touchend', handleDraggableMouseup)
      } else {
        dragger.removeEventListener('mousemove', handleDraggableMousemove)
        dragger.removeEventListener('touchmove', handleDraggableMousemove)
        dragger.removeEventListener('mouseup', handleDraggableMouseup)
        dragger.removeEventListener('touchend', handleDraggableMouseup)
      }
      event.preventDefault()
    }
    dragger.addEventListener('mousedown', handleDraggableMousedown)
    dragger.addEventListener('touchstart', handleDraggableMousedown)
  }
}

const ensureShiftInWindowBounds = () => {
  const maxLeft = viewportWidth.value - trueWidth.value
  const maxTop = Math.max(viewportHeight.value - trueHeight.value, 0)
  const left = shiftLeft.value + shiftX.value * maxLeft
  const top = shiftTop.value + shiftY.value * maxTop
  shiftLeft.value -= left - inRange(0, maxLeft, left)
  shiftTop.value -= top - inRange(0, maxTop, top)
}
const inRange = (from, to, value) => {
  return value < from ? from : value > to ? to : value
}

useHead({
  script: [
    {
      src: 'https://assets.vgjt.info/js/mn.js',
      async: true,
      defer: true,
      body: true,
      type: 'module',
      crossorigin: ''
    }
  ]
})

onMounted(() => {
  window.addEventListener('resize', setDimensions)
})
</script>

<style lang="scss" scoped src="assets/scss/components/common/mini-game.scss" />
